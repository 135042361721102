<template>
  <b-card
    v-if="(files.length > 0)"
    class="roadmap-slider"
  >
    <div
      v-for="(imageFile,imageFileIndex) in files"
      :key="imageFileIndex"
      class="mb-2 roadmap-dev"
    >
      <h6 class="display-4 text-center">
        {{ imageFile.title }}
      </h6>
      <v-lazy-image
        :src="imageFile.file"
        :alt="imageFile.title"
      />
    </div>
  </b-card>
  <b-card v-else>
    <b>No files to display now</b>
  </b-card>
</template>

<script>

import VLazyImage from 'v-lazy-image/v2'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'RoadmapSlider',
  components: {
    VLazyImage,
  },
  data() {
    return {
      files: [],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.$http.get(this.$resources.roadmap.files.index).then(response => {
        this.files = response.data.data.files
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.roadmap-slider{
  .roadmap-dev {
    height: 95wh;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
